import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact SCE" />

      <Helmet
        bodyAttributes={{
          class: "bg-power-lines",
        }}
      />
      <div className="py-24 w-full px-4 md:px-0 md:w-3/5 mx-auto">
        <div className="border-b border-gray-800 mb-8 pb-8">
          <h1 className="uppercase font-bold tracking-wide mb-4 text-5xl">
            Contact Us
          </h1>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full md:w-2/3">
            <form action="https://formspree.io/meqkjobd" method="POST">
              <div className="mb-8">
                <label
                  className="block text-sm font-bold mb-2 sr-only"
                  htmlFor="contactName"
                >
                  Your Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full md:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="contactName"
                  name="contactName"
                  type="text"
                  placeholder="Your Name"
                />
              </div>
              <div className="mb-8">
                <label
                  className="block text-sm font-bold mb-2 sr-only"
                  htmlFor="contactEmail"
                >
                  E-mail Address
                </label>
                <input
                  className="shadow appearance-none border rounded w-full md:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="contactEmail"
                  name="contactEmail"
                  type="text"
                  placeholder="E-mail Address"
                />
              </div>
              <div className="mb-8">
                <label
                  className="block text-sm font-bold mb-2 sr-only"
                  htmlFor="contactPhone"
                >
                  Phone Number
                </label>
                <input
                  className="shadow appearance-none border rounded w-full md:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="contactPhone"
                  name="contactPhone"
                  type="tel"
                  placeholder="Phone Number"
                />
              </div>
              <div className="mb-8">
                <label
                  className="block text-sm font-bold mb-2 sr-only"
                  htmlFor="contactMessage"
                >
                  How Can We Help You
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full md:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="contactMessage"
                  name="contactMessage"
                  placeholder="How Can We Help You?"
                  rows={8}
                ></textarea>
              </div>
              <div className="mb-8">
                <button className="w-full md:w-3/4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                  Send Message
                </button>
              </div>
            </form>
          </div>
          <div className="hidden md:block w-full md:w-1/3 pl-6">
            <span className="font-bold block">Office</span>
            <address className="not-italic leading-loose">
              600 Minuet Ln <br />
              Charlotte, NC 28217 <br />
            </address>
            <span>704.523.6045</span>
            <span className="font-bold block mt-4">PO Box</span>
            <address className="not-italic pb-6 leading-loose">
              PO Box 240436 <br />
              Charlotte, NC 28224
            </address>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
